import React from "react";

function RenCreditSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="url(#paint0_radial_225_11039)"
        d="M3.929 18H1.35V6.899A6.939 6.939 0 018.249 0h8.522v6.84a6.903 6.903 0 01-6.902 6.899h-4.32l.958-2.579H9.81a4.3 4.3 0 004.32-4.32V2.579H8.19a4.302 4.302 0 00-4.32 4.32V18h.059z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_225_11039"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="translate(1.868 17.276) scale(14.931)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6005E"></stop>
          <stop offset="1" stopColor="#E3448A"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default RenCreditSvg;