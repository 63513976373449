import { routes } from "@/constants/routes";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { FC, useEffect, useState } from "react";
import { HeaderSetting, Pagination } from "../_common/_comp";
import PanelLightSetting from "../_common/PanelLightSetting";

import { useFilter } from "@/libs/context/FilterContext/FilterContext";
import { Loader } from "@/libs/UI/Jammer";
import { useGetAllNews } from "@/services/API/news/hook";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import AddNewItem from "../_common/PanelLightSetting/addNewItem/AddNewItem";
import ListAddedNews from "./ListAddedNews/ListAddedNews";
import s from "./WrapperNews.module.scss";
const INIT_PAGIN = {
	total: 0,
	limit: 15,
	offset: 0,
};
const WrapperNews: FC = () => {
	const { getLocalization } = useLanguage();
	const {
		settingObj: {
			news: { currentLang },
		},
		updateSetting,
	} = useSettingStore();
	const [pagin, setPagin] = useState(INIT_PAGIN);
	const { filters } = useFilter()
	
	const {
		data: newsData,
		isLoading,
		refetch,
	} = useGetAllNews({
		lang: currentLang,
		limit: pagin.limit,
		offset: pagin.offset,
		filters
	});

	////
	const step = (step: number) => {
		setPagin((prev) => ({
			...prev,
			offset: step,
		}));
	};
	////

	useEffect(() => {
		refetch();
	}, [pagin.limit, pagin.offset, filters]);

	useEffect(() => {
		setPagin(INIT_PAGIN);
	}, [currentLang]);

	useEffect(() => {
		if (newsData?.total) {
			setPagin((prev) => ({
				...prev,
				total: newsData.total,
			}));
		}
		else {
			setPagin((prev) => ({
				...prev,
				total: 0,
			}));
		}
	}, [newsData?.total]);
	return (
		<div className={s.wrapper}>
			<div className={s.header}>
				<HeaderSetting title={getLocalization("Новости")} />
				<AddNewItem labelBtn={getLocalization("Добавить новость")} link={routes.ADD_NEWS} />
			</div>
			<PanelLightSetting
				bindLang={{
					lang: currentLang,
					callbackLang: (lang) =>
						updateSetting({
							_key: "currentLang",
							resource: "news",
							value: lang,
						}),
				}}
			/>
			{isLoading && <Loader params={{ visible: isLoading }} />}
			{newsData?.data && (
				<>
					<ListAddedNews variantContent="news" data={newsData.data} />
					{pagin.total > pagin.limit && (
						<Pagination
							step={step}
							total={pagin.total}
							limit={pagin.limit}
							offset={pagin.offset}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default WrapperNews;
