import WrapperAddNewCasino from "@/features/Constructor/Casino/WrapperAddNewCasino";
import React from "react";
import { useLocation } from "react-router-dom";
import { TLocationState } from "./type";

export const UpdateCasino = () => {
	const { state }: TLocationState = useLocation();
	return (
		<WrapperAddNewCasino
			editFor="UPDATE"
			labelPage="Обновление казино"
			id={state.itemId}
		/>
	);
};

export default UpdateCasino;
