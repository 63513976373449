export const payments = [
	{ id: 1, title: "ada", icon: "ada" },
	{ id: 2, title: "dot", icon: "dot" },
	{ id: 3, title: "maestro", icon: "maestro" },
	{ id: 4, title: "shiberc20", icon: "shiberc20" },
	{ id: 5, title: "vet", icon: "vet" },
	{ id: 6, title: "alfab", icon: "alfab" },
	{ id: 7, title: "eos", icon: "eos" },
	{ id: 8, title: "mastercard", icon: "mastercard" },
	{ id: 9, title: "sol", icon: "sol" },
	{ id: 10, title: "visa", icon: "visa" },
	{ id: 11, title: "atom", icon: "atom" },
	{ id: 12, title: "etc", icon: "etc" },
	{ id: 13, title: "mir", icon: "mir" },
	{ id: 14, title: "tbank", icon: "tbank" },
	{ id: 15, title: "VTB", icon: "VTB" },
	{ id: 16, title: "bnb", icon: "bnb" },
	{ id: 17, title: "eth", icon: "eth" },
	{ id: 18, title: "mkr", icon: "mkr" },
	{ id: 19, title: "ton", icon: "ton" },
	{ id: 20, title: "webmoney", icon: "webmoney" },
	{ id: 21, title: "btc", icon: "btc" },
	{ id: 22, title: "ethw", icon: "ethw" },
	{ id: 23, title: "neo", icon: "neo" },
	{ id: 24, title: "trx", icon: "trx" },
	{ id: 25, title: "xlm", icon: "xlm" },
	{ id: 26, title: "btg", icon: "btg" },
	{ id: 27, title: "fkwallet", icon: "fkwallet" },
	{ id: 28, title: "paypal", icon: "paypal" },
	{ id: 29, title: "tusd", icon: "tusd" },
	{ id: 30, title: "xmr", icon: "xmr" },
	{ id: 31, title: "btt", icon: "btt" },
	{ id: 32, title: "ftm", icon: "ftm" },
	{ id: 33, title: "piastrix 1", icon: "piastrix 1" },
	{ id: 34, title: "uni", icon: "uni" },
	{ id: 35, title: "xrp", icon: "xrp" },
	{ id: 36, title: "busd", icon: "busd" },
	{ id: 37, title: "icx", icon: "icx" },
	{ id: 38, title: "RAIF", icon: "RAIF" },
	{ id: 39, title: "usdc", icon: "usdc" },
	{ id: 40, title: "xvg", icon: "xvg" },
	{ id: 41, title: "cardrub", icon: "cardrub" },
	{ id: 42, title: "iot", icon: "iot" },
	{ id: 43, title: "rvn", icon: "rvn" },
	{ id: 44, title: "usdctrc", icon: "usdctrc" },
	{ id: 45, title: "yamrub", icon: "yamrub" },
	{ id: 46, title: "cro", icon: "cro" },
	{ id: 47, title: "kmd", icon: "kmd" },
	{ id: 48, title: "SBER", icon: "SBER" },
	{ id: 49, title: "usdt", icon: "usdt" },
	{ id: 50, title: "yfi", icon: "yfi" },
	{ id: 51, title: "doge", icon: "doge" },
	{ id: 52, title: "ltc", icon: "ltc" },
	{ id: 53, title: "sbprub", icon: "sbprub" },
	{ id: 54, title: "usdttrc", icon: "usdttrc" },
	{ id: 55, title: "zec", icon: "zec" },
];
