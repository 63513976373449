import { API } from "@/services/helpers/conf-axios";
import {
	TCasinoCreateReq,
	TCasinoDeleteRes,
	TCasinoListRes,
	TParamsCasinoListReq,
	TParamsCasinoUpdateReq,
	TParamsSingleCasinoReq,
	TSingleCasinoRes,
} from "./casino.type";
import { SERVICES } from "@/constants/api";

export const getTopCasino = async ({
	lang,
	limit,
	offset,
	filters
}: TParamsCasinoListReq): Promise<TCasinoListRes> => {
	try {
		const response = await API.get<any, { data: TCasinoListRes }>(
			`${SERVICES.casino}/top`,
			{
				params: {
					language: lang,
					limit: limit,
					offset: offset,
					...filters
				},
			}
		);
		return response.data;
	} catch {
		return {
			data: [],
		} as any;
		throw new Error("fetch casino error");
	}
};

export const getCasinoById = async ({
	lang,
	id,
}: TParamsSingleCasinoReq): Promise<TSingleCasinoRes> => {
	try {
		const response = await API.get<any, { data: TSingleCasinoRes }>(
			`${SERVICES.casino}/${id}?language=${lang}`
		);
		return response.data;
	} catch {
		throw new Error("fetch casino by id error");
	}
};

export const createCasinoById = async ({
	casino,
	language,
}: TCasinoCreateReq): Promise<TSingleCasinoRes> => {
	try {
		const response = await API.post<
			TCasinoCreateReq,
			{ data: TSingleCasinoRes }
		>(`${SERVICES.casino}/`, {
			casino,
			language,
		});
		return response.data;
	} catch {
		throw new Error("create casino error");
	}
};

export const updateCasinoById = async ({
	id,
	body,
}: TParamsCasinoUpdateReq): Promise<TSingleCasinoRes> => {
	try {
		const response = await API.patch(`${SERVICES.casino}/${id}`, {
			...body,
		});
		return response.data;
	} catch {
		throw new Error("update casino by id error");
	}
};

export const deleteCasinoById = async ({
	lang,
	id,
}: TCasinoDeleteRes): Promise<any> => {
	try {
		const response = await API.delete(
			`${SERVICES.casino}/${id}?language=${lang}`
		);
		return response.data;
	} catch {
		throw new Error("delete casino by id error");
	}
};
