import React from "react";

const ImageDeleteSvg = () => {
	return (
		<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	  >
		<path
		  stroke="#FF4949"
		  d="M8 6.142V4.471c0-1.165 0-1.747.35-2.11C8.698 2 9.26 2 10.385 2h3.228c1.125 0 1.687 0 2.037.362.349.362.349.944.349 2.109v1.671m2.115 9.382c-.148 1.348-.253 2.284-.407 3.001-.15.699-.327 1.087-.562 1.374a3 3 0 01-.69.618c-.31.201-.716.335-1.427.408-.73.074-1.672.075-3.028.075s-2.298-.001-3.028-.075c-.71-.073-1.117-.207-1.427-.408a3 3 0 01-.69-.618c-.235-.287-.412-.675-.562-1.374-.154-.717-.258-1.653-.407-3.001L4.88 6.43h14.24l-1.005 9.094zM3 6.122h18"
		></path>
	  </svg>
	);
};

export default ImageDeleteSvg;
