import { useMutation, useQuery, useQueryClient } from "react-query";
import { getRobots, updateRobots } from "./robots";
import { TRobotsUpdateReq } from "./robots.type";
import { toast } from "react-toastify";

export const useGetRobots = () => {
	return useQuery(["robots"], () => getRobots(), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log("get robots", error);
		},
	});
};

export const useUpdateRobots = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async (params: TRobotsUpdateReq[]) => {
			return await updateRobots(params);
		},
		{
			onSuccess: (data) => {
				toast.success("Robots.txt изменен");
				queryClient.invalidateQueries("robots");
			},
			onError: (error) => {
				toast.error("Ошибка изменения");
				console.log("update Robots.txt", error);
			},
		}
	);
};
