import React from "react";

const InputFileSvg = () => {
	return (
		<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	  >
		<path stroke="#fff" d="M7 12l5 5m0 0l5-5m-5 5V4M6 20h12"></path>
	  </svg>
	);
};

export default InputFileSvg;
