import NotFound from "@/_assets/images/not-found.jpg";
import { BASE_CDN_URL } from "@/constants/api";
import { FC, useState } from "react";

type TProps = {
	src: string;
	alt: string;
};

const ImageCdn: FC<TProps> = ({ src, alt }) => {
	const [isError, setIsError] = useState(false);
	return (
		<img
			onError={() => setIsError(true)}
			src={isError ? NotFound : `${BASE_CDN_URL}/${src}`}
			alt={alt}
		/>
	);
};

export default ImageCdn;
