import { Loader } from "@/libs/UI/Jammer";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { useGetRobots, useUpdateRobots } from "@/services/API/robots/hook";
import { TRobotsGetRes } from "@/services/API/robots/robots.type";
import { useRobotsStore } from "@/store/robotsStore/robotsStore";
import { useCallback, useEffect, useState } from "react";
import WrapperAddNewResource from "../_common/_comp/WrapperAddNewResource";
import RobotsEdit from "./RobotsEdit/RobotsEdit";
import s from "./WrapperRobots.module.scss";
import { ActionRightBlock } from "./_comp";

const WrapperRobots = () => {
	const [isActiveEdit, setActiveEdit] = useState(false);
	const [saveOld, setSaveOld] = useState<TRobotsGetRes[]>([]);
	const { data, isLoading, isError, isSuccess } = useGetRobots();
	const { getForReq, setStore } = useRobotsStore();
	const { mutateAsync: updateRobots } = useUpdateRobots();
	const { getLocalization } = useLanguage();
	//
	const save = useCallback(() => {
		updateRobots(getForReq());
	}, []);
	const reset = useCallback(() => {
		setStore(saveOld);
	}, [saveOld]);
	const changeActive = useCallback(() => {
		setActiveEdit((prev) => !prev);
	}, []);
	///
	useEffect(() => {
		if (isSuccess && data) {
			setStore(data);
			setSaveOld(data);
		}
	}, [data, isSuccess]);

	///
	if (isLoading) {
		return <Loader params={{ visible: isLoading }} />;
	}
	return (
		<WrapperAddNewResource
			title={getLocalization("Robots")}
			goBack={true}
			rightComp={
				<ActionRightBlock {...{ isActiveEdit, save, reset, changeActive }} />
			}
		>
			<div className={s.wrap}>
				<div className={s.main_block}>
					{isLoading ? (
						<Loader params={{ visible: isLoading }} />
					) : (
						<RobotsEdit {...{ isActiveEdit }} />
					)}
				</div>
			</div>
		</WrapperAddNewResource>
	);
};

export default WrapperRobots;
