import { routes } from "@/constants/routes";
import { useUpdateNewsById } from "@/services/API/news/hook";
import { TNewsItemRes, TUpdateNews } from "@/services/API/news/news.type";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { TVariantNewResource } from "@/store/newResourceStore/type";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ItemAddedContent } from "../../_common/_comp";
import s from "./ListAddedNews.module.scss";

type TProps = {
	variantContent: TVariantNewResource;
	data: TNewsItemRes[];
};
const ListAddedNews: FC<TProps> = ({ variantContent, data }) => {
	const navigate = useNavigate();
	const settings = useSettingStore();
	const { mutateAsync: updateItem } = useUpdateNewsById();
	const fetchUpdate = async (params: TUpdateNews) => {
		await updateItem({
			id: params.id,
			body: {
				news: { ...params, hidden: !params.hidden },
				language: {
					lang: settings.getCurrentLang("news"),
				},
			},
		});
	};

	return (
		<div className={s.list}>
			{data.map((news) => {
				return (
					<ItemAddedContent
						key={news.id}
						id={news.id}
						isHidden={news.hidden}
						title={news.title}
						variantContent={variantContent}
						callbackVisible={() => {
							fetchUpdate(news);
						}}
						callbackEdit={() => {
							navigate(routes.UPDATE_NEWS, {
								state: {
									itemId: news.id,
								},
							});
						}}
					/>
				);
			})}
		</div>
	);
};
export default ListAddedNews;
