import ArrowPaginationSvg from "@/_assets/svg/ArrowPaginationSvg";
import { P } from "@/libs/UI/CustomTags";
import cn from "classnames";
import { FC, useEffect } from "react";
import { usePagination } from "./_hooks/use-pagination";
import s from "./Pagination.module.scss";

type TProps = {
	total: number;
	offset: number;
	limit: number;
	step: (params: number) => void;
};

const Pagination: FC<TProps> = ({ total, offset, limit, step }) => {
	const { currentPage, totalPages, getPages, bind } = usePagination({
		limit,
		offset,
		total,
	});

	useEffect(() => {
		const newOffset = (currentPage - 1) * limit;
		step(newOffset);
	}, [currentPage, limit]);

	return (
		<div className={s.pagination}>
			<div
				className={cn(s.circle, s.arrow, s.arrowLeft, {
					[s.inactive]: currentPage === 1,
				})}
				onClick={bind.handlePrevClick}
			>
				<ArrowPaginationSvg isInactive={currentPage === 1} />
			</div>
			<div className={s.wrapNumbers}>
				{getPages.left.map((page) => (
					<div
						key={page}
						className={cn(s.circle, s.number, {
							[s.active]: page === currentPage,
						})}
						onClick={() => bind.handlePageClick(page)}
					>
						<P size="s">{page}</P>
					</div>
				))}
				{getPages.center && (
					<>
						<div>....</div>
						{getPages.center.map((page) => (
							<div
								key={page}
								className={cn(s.circle, s.number, {
									[s.active]: page === currentPage,
								})}
								onClick={() => bind.handlePageClick(page)}
							>
								<P size="s">{page}</P>
							</div>
						))}
					</>
				)}
				{getPages.right && (
					<>
						<div>....</div>
						{getPages.right.map((page) => (
							<div
								key={page}
								className={cn(s.circle, s.number, {
									[s.active]: page === currentPage,
								})}
								onClick={() => bind.handlePageClick(page)}
							>
								<P size="s">{page}</P>
							</div>
						))}
					</>
				)}
			</div>
			<div
				className={cn(s.circle, s.arrow, s.arrowRight, {
					[s.inactive]: currentPage === totalPages,
				})}
				onClick={bind.handleNextClick}
			>
				<ArrowPaginationSvg isInactive={currentPage === totalPages} />
			</div>
		</div>
	);
};

export default Pagination;
