import s from "./SeoResource.module.scss";

import Select from "@/features/Constructor/_common/_comp/Select/Select";
import UploadImage from "@/features/Constructor/_common/_comp/UploadImage/UploadImage";
import { Input } from "@/libs/UI";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { useSeoStore } from "@/store/newResourceStore/news";
import { TTypeTagNews } from "@/store/newResourceStore/news/seo/seo.type";
import { TTagResource } from "@/store/newResourceStore/type";
import ColorPicker from "./../../../_common/_comp/ColorPicker/ColorPicker";

const testData = [
	{
		id: 1,
		title: "Партнерские программы",
	},
	{
		id: 2,
		title: "Аналитика слотов",
	},
	{
		id: 3,
		title: "Интервью",
	},
	{
		id: 4,
		title: "Правила игр",
	},
	{
		id: 5,
		title: "Знаменитости",
	},
	{
		id: 7,
		title: "Топ слотов",
	},
	{
		id: 8,
		title: "Культовые казино",
	},
] as TTypeTagNews[];

const Added = (item: TTagResource) => {
	return <p>{item.title}</p>;
};
const Feature = (item: TTagResource) => {
	return <p>{item.title}</p>;
};

const SeoResource = () => {
	const { seoData, setSeoData, setTags } = useSeoStore();

	const settings = useSettingStore();
	return (
		<div className={s.seo}>
			<div className={s.inputFile_block}>
				<p>Обложка</p>
				<div className={s.inputFile}>
					<UploadImage
						fileURL={seoData.fileURL}
						onChange={(e) => setSeoData({ field: "fileURL", value: e })}
						idInput={"seo-news"}
					/>
				</div>
			</div>

			<div className={s.colorPicker}>
				<ColorPicker
					onChange={(color) => {
						setSeoData({ field: "color", value: color });
					}}
					activeColor={seoData.color}
					label="Цвет новости"
				/>
			</div>

			<div className={s.inputs_block}>
				<p>Meta {settings.getCurrentLang("news").toUpperCase()}</p>
				<div className={s.inputs}>
					<Input
						placeholder="Ссылка"
						value={seoData.link}
						onChange={(e) => {
							setSeoData({
								field: "link",
								value: e.target.value,
							});
						}}
					/>
					<Input
						placeholder="Meta Title"
						value={seoData.meta_title}
						onChange={(e) => {
							setSeoData({
								field: "meta_title",
								value: e.target.value,
							});
						}}
					/>
					<Input
						placeholder="Meta Description"
						value={seoData.meta_description}
						onChange={(e) => {
							setSeoData({
								field: "meta_description",
								value: e.target.value,
							});
						}}
					/>
					<Input
						placeholder="Title"
						value={seoData.title}
						onChange={(e) => {
							setSeoData({
								field: "title",
								value: e.target.value,
							});
						}}
					/>
					<Input
						placeholder="Description"
						value={seoData.description}
						onChange={(e) => {
							setSeoData({
								field: "description",
								value: e.target.value,
							});
						}}
					/>

					<Select
						dataAdded={seoData.tags}
						value={testData}
						DataComponent={(e) => <Added title={e.item.title} id={e.item.id} />}
						ValueComponent={(e) => (
							<Feature title={e.item.title} id={e.item.id} />
						)}
						placeholder="Tags"
						onChange={(e) => setTags({ field: "tags", tag: e })}
					/>
				</div>
			</div>
		</div>
	);
};

export default SeoResource;
