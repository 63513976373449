import { routes } from "@/constants/routes";
import { TVariantNewResource } from "@/store/newResourceStore/type";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ItemAddedContent } from "../../_common/_comp";
import s from "./ListAddedCasino.module.scss";
import { useUpdateCasinoById } from "@/services/API/casino/hook";
import {
	TCasinoItemRes,
	TUpdateCasino,
} from "@/services/API/casino/casino.type";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
type TProps = {
	variantContent: TVariantNewResource;
	data: TCasinoItemRes[];
};
const ListAddedCasino: FC<TProps> = ({ variantContent, data }) => {
	const navigate = useNavigate();
	const settings = useSettingStore();
	const { mutateAsync: updateItem } = useUpdateCasinoById();
	const fetchUpdate = async (params: TUpdateCasino) => {
		await updateItem({
			id: params.id,
			body: {
				casino: { ...params, hidden: !params.hidden },
				language: {
					lang: settings.getCurrentLang("casino"),
				},
			},
		});
	};
	return (
		<div className={s.list}>
			{data.map((casino) => {
				return (
					<ItemAddedContent
						key={casino.id}
						id={casino.id}
						isHidden={casino.hidden}
						title={casino.name}
						variantContent={variantContent}
						callbackVisible={() => {
							fetchUpdate(casino);
						}}
						callbackEdit={() => {
							navigate(routes.UPDATE_CASINO, {
								state: {
									itemId: casino.id,
								},
							});
						}}
					/>
				);
			})}
		</div>
	);
};
export default ListAddedCasino;
