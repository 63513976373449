import { Outlet } from "react-router-dom";
const Casino = () => {
	return (
		<>
			<Outlet />
		</>
	);
};

export default Casino;
