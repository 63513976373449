import React from 'react'

const PlusColumnSvg = () => {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="27"
        fill="none"
        viewBox="0 0 26 27"
    >
        <rect width="26" height="26" y="0.5" fill="#2A353C" rx="13"></rect>
        <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.167"
        d="M17.166 13.5h-4.124m0 0H8.917m4.125 0V9.375m0 4.125v4.125"
        ></path>
    </svg>
  )
}

export default PlusColumnSvg