import { toastGetItemLang } from "@/libs/utils/toastGetAllLang";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
	createNewsById,
	deleteNewsById,
	getAllNews,
	getNewsById,
	updateNewsById,
} from "./news";
import {
	TNewsCreateReq,
	TNewsDeleteRes,
	TParamsNewsListReq,
	TParamsNewsUpdateReq,
	TParamsSingleNewsReq,
} from "./news.type";

export const useGetAllNews = (params: TParamsNewsListReq) => {
	return useQuery(["news", params.lang], () => getAllNews(params), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log("get all news", error);
		},
	});
};
export const useGetNewsById = () => {
	return useMutation(
		async ({ lang, id }: TParamsSingleNewsReq) => {
			const [data] = await Promise.allSettled([getNewsById({ lang, id })]);
			toastGetItemLang({
				lang,
				status: data.status,
			});

			return {
				dataRes: data.status === "fulfilled" ? data.value : null,
				error: data.status === "rejected" ? data.reason : null,
			};
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log("get all langs news", error);
			},
		}
	);
};

export const useCreateNews = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ news, language }: TNewsCreateReq) => {
			return await createNewsById({ news, language });
		},
		{
			onSuccess: (data) => {
				toast.success("Новость добавлена");
				queryClient.invalidateQueries("news");
			},
			onError: (error) => {
				toast.error("Ошибка добавления");
				console.log("create news", error);
			},
		}
	);
};
export const useUpdateNewsById = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ id, body }: TParamsNewsUpdateReq) => {
			console.log(id, body);
			return await updateNewsById({ id, body });
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("news");
				toast.success("Новость обновлена");
			},
			onError: (error) => {
				toast.error("Ошибка обновления");
				console.log("update news", error);
			},
		}
	);
};

export const useDeleteNewsById = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ lang, id }: TNewsDeleteRes) => {
			return await deleteNewsById({ lang, id });
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("news");
				toast.success("Новость удалена");
			},
			onError: (error) => {
				toast.error("Ошибка удаления");
				console.log("delete news", error);
			},
		}
	);
};
