import { SelectorHOC } from "@/libs/HOC";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { FC } from "react";
import { TKeySelectorName, TPublicatedSelector, TPublicatedSelectorKey } from "../type";
import ItemStatus from "./ItemStatus/ItemStatus";
import s from "./PublicatedFilter.module.scss";
type TProps = {
	dataSelector: TPublicatedSelector[];
	updateDataSelector: (key: TPublicatedSelectorKey) => void;
	keyName: TKeySelectorName;
};
const PublicatedFilter: FC<TProps> = ({ dataSelector, updateDataSelector, keyName }) => {
	const { getLocalization } = useLanguage();

	return (
		<div className={s.wrap}>
			<SelectorHOC keyName={keyName} background="var(--casino-bg-blue-grey)">
				<ItemStatus {...{ dataSelector, updateDataSelector }} />
			</SelectorHOC>
		</div>
	);
};
export default PublicatedFilter;
