import React, { useEffect, useState } from 'react'
import s from './AscDescFilter.module.scss'
import { TAscDescKey } from '../type'
import AscSvg from '@/_assets/svg/filters/AscSvg'
import DescSvg from '@/_assets/svg/filters/DescSvg'
import { useFilter } from '@/libs/context/FilterContext/FilterContext'
const AscDescFilter = () => {

  const { onChangeFilter, filters } = useFilter()
  const handleChange = ({ascDesc} : {ascDesc: TAscDescKey}) => {
    onChangeFilter({ key: 'order', value: ascDesc })
  }

  return (
    <div 
      className={s.wrapper} 
      onClick={()=>handleChange({ascDesc: filters.order === 'asc' ? 'desc' : 'asc'})}
    >
      {filters.order === 'asc' ? <AscSvg /> : <DescSvg />}
    </div>
  )
}

export default AscDescFilter