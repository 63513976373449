import React from "react";

function ProviderBSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path fill="url(#pattern0_225_11100)" d="M0 0H18V18H0z"></path>
      <defs>
        <pattern
          id="pattern0_225_11100"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.02273)" xlinkHref="#image0_225_11100"></use>
        </pattern>
        <image
          id="image0_225_11100"
          width="44"
          height="44"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAAAXNSR0IArs4c6QAAAsxJREFUWEftmTuMTVEUhr/f+x2PEJUG1RSMxgiRKSQ6YoKQeBSEiESDEcQrHplEofCIRMMUkzDFJBqFSqIQQuNRkJCg8BiUEq/lLLnkOrPPnXMn98y+wz3Jbe7Z66zvrL32v9beRwQuMzsHjA/dG8T/3ktqT/tTBvA3YPggwoVc9Uqanhf4AzA1MvBzSbMbwAXOQiPCBQb316P/3wgbcB54VkH6fMxYYEbp1wTMq3JKahbhH8B8SQ/zApjZSGBp8oLHgUU57WoKvFjSnZyO/wwzs1HAbuBUDtv4wL8hE3AH3t8PdLHAZuZVaRrgKeM57A4/ZZT+0cAjYE4F6MKBLwMbSsDO8QroBo5J+pwGM7PTwJ6YwJ3AxgDATkkXAsArEuXoAYZlQBce4StJGmwKOL8NtEr6Xn7PzBYAt4AJsYA9JTYHnLteN0n6mgJuLgFPjAWcFeHHQHMAeBlwAxgRCzgrwmcl7Qrk8AHgZMxFdw1YkwJ4UcrflwHg+4DncdZV+KJb6bla0mCH6PUpl/Q6ALsX6KigEG5SLHCOUouZzQR2JFp9OMf4ugD2xucIsHxIAJf1EtuAM8C4mDm8LsnZhUB5gXgDdEp6F8hjVwhXimiLzvuG1QHvrsNtkp6mCocXDFeKufWmw85zSNKJQJSzik3xKmFmlZx3S1obAPYFeDRWhCsBX5fkOv3XZWZbgUuxgLNKs/P0SGoLAG8HLsYCrhThDkl9tkRm5tVuXyzgq0CfPAVcHVYlOfwkEOEuYH0sYJ/eVsD7Xj/G9SNbl62upFv7GID1/d8DYFYU4BylNr3gXOYORisc1QCb2RLgZtKxjRks4BZJ96qB9LFm5tt7Tx3X3in92Ne0W/Ptzd0cwL79mVw6X/OztS1ASw67mlY6f9jbpHH5ksOxfyfxXfGkHGPTQ2oW4QH4HpBJA3hAYavC6N+O8JD7sFi3n25/Al7I8zxbthNTAAAAAElFTkSuQmCC"
        ></image>
      </defs>
    </svg>
  );
}

export default ProviderBSvg;