import WrapperAddNewArticle from "@/features/Constructor/Article/WrapperAddNewArticle";
import { useLocation } from "react-router-dom";
import { TLocationState } from "./type";

export const UpdateArticle = () => {
	const { state }: TLocationState = useLocation();

	return (
		<WrapperAddNewArticle
			editFor="UPDATE"
			labelPage="Обновление статьи"
			id={state.itemId}
		/>
	);
};

export default UpdateArticle;
