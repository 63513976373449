import { API } from "@/services/helpers";
import { TRobotsUpdateReq, TRobotsGetRes } from "./robots.type";
import { SERVICES } from "@/constants/api";

export const getRobots = async (): Promise<TRobotsGetRes[]> => {
	try {
		const response = await API.get<any, { data: TRobotsGetRes[] }>(
			`${SERVICES.robots}/`
		);
		return response.data;
	} catch {
		return {
			data: [],
		} as any;
	}
};

export const updateRobots = async (params: TRobotsUpdateReq[]) => {
	try {
		const res = await API.patch<
			any,
			{ data: TRobotsGetRes },
			TRobotsUpdateReq[]
		>(`${SERVICES.robots}/`, [...params]);
		return res.data;
	} catch (error) {}
};
