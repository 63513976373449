import { Input } from '@/libs/UI'
import { useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import s from './PublishCalendar.module.scss'
import { Button } from '@/libs/UI/CustomTags'
import CalendarSvg from '@/_assets/svg/CalendarSvg'
import EditSvg from '@/_assets/svg/EditSvg'
import cn from 'classnames'

type TProps = {
	value: string
	onChange: (value: string) => void
}

const PublishCalendar = ({ value, onChange }: TProps) => {
	const initialMinDate = new Date()
	initialMinDate.setHours(initialMinDate.getHours() + 3)
	const [stateDate, setStateDate] = useState<Date>(new Date())
	const [stateTime, setStateTime] = useState({
		hours: '00',
		minutes: '00',
	})

	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isEdit, setIsEdit] = useState<boolean>(false)
	const [calendarPosition, setCalendarPosition] = useState<'top' | 'bottom'>(
		'bottom'
	)
	const inputRef = useRef<HTMLInputElement | null>(null)

	useEffect(() => {
		setStateDate(new Date(Date.parse(value)))
		setStateTime({
			hours: String(new Date(value).getHours()),
			minutes: String(new Date(value).getMinutes()),
		})
	}, [value])

	const onDateChange = (date: any) => {
		setIsOpen(false)
		setStateDate(date)
	}

	const handleSave = () => {
		setStateTime((prev) => {
			let currentHours = prev.hours
			let currentMinutes = prev.minutes
			const now = new Date()

			if (prev.hours.length > 2) currentHours = prev.hours.slice(0, 2)
			if (Number(prev.hours) > 23) currentHours = '23'
			if (new Date(stateDate) < now) {
				if (prev.hours.length > 0 && now.getHours() > Number(prev.hours))
					currentHours = String(now.getHours())
			}

			if (prev.minutes.length > 2) currentMinutes = prev.minutes.slice(0, 2)
			if (Number(prev.minutes) > 59) currentMinutes = '59'
			if (new Date(stateDate) < now) {
				if (prev.minutes.length > 0 && now.getMinutes() > Number(prev.minutes))
					currentMinutes = String(now.getMinutes())
			}

			return {
				hours: currentHours,
				minutes: currentMinutes,
			}
		})
		setIsEdit(false)
	}

	useEffect(() => {
		if (!isEdit) {
			onChange(
				new Date(
					stateDate.getFullYear(),
					stateDate.getMonth(),
					stateDate.getDate(),
					Number(stateTime.hours),
					Number(stateTime.minutes)
				).toISOString()
			)
		}
	}, [stateDate, isEdit])

	const handleOpenCalendar = () => {
		if (inputRef.current) {
			const { bottom } = inputRef.current.getBoundingClientRect()
			const calendarHeight = 300
			const availableSpaceBelow = window.innerHeight - bottom

			if (availableSpaceBelow < calendarHeight) {
				setCalendarPosition('bottom')
			} else {
				setCalendarPosition('top')
			}
			setIsOpen(!isOpen)
		}
	}

	return (
		<div className={s.publishCalendar}>
			<div className={s.inputCalendar} onClick={handleOpenCalendar}>
				<Input
					readOnly
					className={s['input-calendar']}
					ref={inputRef}
					value={`${stateDate.getDate().toString().padStart(2, '0')}-${(
						stateDate.getMonth() + 1
					)
						.toString()
						.padStart(2, '0')}-${stateDate.getFullYear()}`}
				/>
				<CalendarSvg />
			</div>
			{isOpen && (
				<div
					style={{
						position: 'absolute',
						[calendarPosition]:
							calendarPosition === 'top' ? 'var(--s50)' : '100%',
					}}
				>
					<Calendar
						className={s['react-calendar']}
						onChange={onDateChange}
						minDate={initialMinDate}
						value={new Date(value)}
					/>
				</div>
			)}
			<div className={s.timePicker}>
				<div className={cn(s.timePicker_inputs, !isEdit && s.inActive)}>
					<Input
						disabled={!isEdit}
						type="number"
						placeholder="HH"
						value={stateTime.hours}
						onChange={(e) =>
							setStateTime({ ...stateTime, hours: e.target.value })
						}
					/>
					<p className={s.dots}>:</p>
					<Input
						disabled={!isEdit}
						type="number"
						placeholder="MM"
						value={stateTime.minutes}
						onChange={(e) =>
							setStateTime({ ...stateTime, minutes: e.target.value })
						}
					/>
				</div>
				{isEdit ? (
					<Button onClick={() => handleSave()} type="add">
						Сохранить
					</Button>
				) : (
					<div className={s.edit} onClick={() => setIsEdit(true)}>
						<EditSvg />
					</div>
				)}
			</div>
		</div>
	)
}

export default PublishCalendar
