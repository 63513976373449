import React from 'react'

const AscSvg = () => {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
    >
        <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M2.5 13.334l3.333 3.333 3.334-3.334M5.833 16.667V3.333M15.833 5A1.667 1.667 0 1012.5 5v1.667a1.667 1.667 0 003.333 0V5zM14.167 16.666v-5H12.5M12.5 16.666h3.333"
        ></path>
    </svg>
  )
}

export default AscSvg