import { useLanguage } from '@/libs/context/LanguageProvider'
import { FC, useEffect, useState } from 'react'
import { TTabItem } from '../../../../components/Tabs/type'
import EmbeddedContent from '../../_common/AddNewResource/EmbeddedContent/EmbeddedContent'
import ReceivingData from '../../_common/AddNewResource/ReceivingData/ReceivingData'
import SettingWidget from '../../_common/AddNewResource/SettingWidget/SettingWidget'
import s from './WrapperAddNewCasino.module.scss'

import Tabs from '@/components/Tabs/Tabs'
import { TCasinoKeysTabs } from '@/store/newResourceStore/casino/casinoStore.type'
import WrapperAddNewResource from '../../_common/_comp/WrapperAddNewResource'
import SeoResource from './SeoResource/SeoResource'

import useTabs from '@/libs/hooks/use-tabs'
import { useConstructorStore } from '@/store/newResourceStore/_common/constructor/constructorStore'
import useCasinoStore from '@/store/newResourceStore/casino'
import { HeaderActions } from '../../_common/_comp'
import { useManipulationData } from '../../_common/_hooks'
import { TEditingData } from '../../_common/_hooks/use-manipulation-data/type'
import { TVariantOpenPage } from '../../Constructor.type'
import CommonResource from './CommonResource/CommonResource'

import { Loader } from '@/libs/UI/Jammer'
import {
	TCasinoCreateReq,
	TCasinoUpdateReq,
	TSingleCasinoRes,
} from '@/services/API/casino/casino.type'
import {
	useCreateCasino,
	useDeleteCasinoById,
	useGetCasinoById,
	useUpdateCasinoById,
} from '@/services/API/casino/hook'
import { useSettingStore } from '@/store/newResourceStore/_common/setting/settingStore'
import { VariantNewResourceContext } from '../../_common/AddNewResource/ReceivingData/_context/VariantNewResourceContext'

const INIT_TABS = [
	{
		key: 'seo',
		label: 'SEO',
		isActive: true,
	},
	{
		key: 'common',
		label: 'Общее',
		isActive: false,
	},
	{
		key: 'review',
		label: 'Обзор',
		isActive: false,
	},
	{
		key: 'bonus',
		label: 'Бонусы',
		isActive: false,
	},
] as TTabItem<TCasinoKeysTabs>[]
const WrapperAddNewCasino: FC<TVariantOpenPage> = ({
	editFor,
	labelPage,
	id = -1,
}) => {
	const { getLocalization } = useLanguage()
	const { activeTab, changeTabs, tabs } = useTabs<TCasinoKeysTabs>(INIT_TABS)

	const { casinoObj, bindTransformData, bindActionData } = useCasinoStore()
	const { bindStore } = useConstructorStore()
	const settings = useSettingStore()
	/////////

	const [editingData, setEditingData] = useState<
		TEditingData<TSingleCasinoRes>
	>({
		data: null,
	})

	const { mutateAsync: createItem } = useCreateCasino()
	const { mutateAsync: deleteItem } = useDeleteCasinoById()
	const { mutateAsync: getItem } = useGetCasinoById()
	const { mutateAsync: updateItem } = useUpdateCasinoById()

	/////////

	const { handleSentData, handleCopyStore, handleLocalRemove } =
		useManipulationData({
			bindActionData: {
				loadLocalData: bindActionData.loadCasinoData,
				removeLocalData: bindActionData.removeCasinoData,
				updateLocalData: bindActionData.updateCasinoData,
			},
			editingData,
			variantResource: 'casino',
			copyArray: casinoObj,
			bindTransformData,
			editFor,
		})

	const fetchSentData = async () => {
		const sentSetting = handleSentData()
		if (sentSetting !== null) {
			if (editFor === 'ADD') {
				await createItem(sentSetting.sentData as TCasinoCreateReq).then(() => {
					sentSetting.clear()
				})
			}
			if (editFor === 'UPDATE') {
				await updateItem({
					id,
					body: sentSetting.sentData as TCasinoUpdateReq,
				}).then(() => {
					sentSetting.clear()
				})
			}
		}
	}

	const fetchDeleteNews = async () => {
		const _id = id || casinoObj?.id || -1
		await deleteItem({
			lang: settings.getCurrentLang('casino'),
			id: _id,
		}).then(() => {
			console.log('success remove news')
			handleLocalRemove()
		})
	}

	/////////

	useEffect(() => {
		if (id > -1) {
			getItem({ id, lang: settings.getCurrentLang('casino') }).then((e) => {
				setEditingData({
					data: e.dataRes,
				})
			})
		}
	}, [id, settings.getCurrentLang('casino')])

	if (editFor === 'UPDATE' && !editingData.data)
		return (
			<Loader
				params={{
					visible: !editingData.data,
				}}
			/>
		)

	return (
		<VariantNewResourceContext.Provider value={{ variantResource: 'casino' }}>
			<WrapperAddNewResource
				title={getLocalization(labelPage)}
				goBack={true}
				rightComp={
					<HeaderActions
						disabled={false}
						handleUpdateStore={bindActionData.updateCasinoData}
						handleCopyStore={handleCopyStore}
					/>
				}
			>
				<div className={s.wrap}>
					<div className={s.main_block}>
						<Tabs
							data={tabs}
							callback={changeTabs}
							saveData={bindActionData.updateCasinoData}
						/>
						{activeTab.key === 'seo' && <SeoResource />}
						{activeTab.key === 'common' && <CommonResource />}
						{activeTab.key === 'review' && <ReceivingData />}
						{activeTab.key === 'bonus' && <div>{activeTab.key}</div>}
					</div>
					<div className={s.widgets_block}>
						{activeTab.key === 'review' && <EmbeddedContent />}
						<SettingWidget
							handleRemove={fetchDeleteNews}
							handleSent={fetchSentData}
							editFor={editFor}
						/>
					</div>
				</div>
			</WrapperAddNewResource>
		</VariantNewResourceContext.Provider>
	)
}
export default WrapperAddNewCasino
