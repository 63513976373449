import React from 'react'

const DescSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 13.3335L5.83333 16.6668L9.16667 13.3335" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.8335 16.6668V3.3335" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.1667 8.3335V3.3335H12.5" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5 8.3335H15.8333" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.8333 13.3332C15.8333 12.4127 15.0871 11.6665 14.1667 11.6665C13.2462 11.6665 12.5 12.4127 12.5 13.3332V14.9998C12.5 15.9203 13.2462 16.6665 14.1667 16.6665C15.0871 16.6665 15.8333 15.9203 15.8333 14.9998V13.3332Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default DescSvg