import { SERVICES } from "@/constants/api";
import { API } from "../../helpers/conf-axios";
import { TUploadImage } from "./upload-image.type";

export const uploadFile = async ({ image, uuid }: TUploadImage) => {
	try {
		const formData = new FormData();
		formData.append("image", image);

		const response = await API.post(`${SERVICES.image_cdn}/${uuid}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return {
			data: response.data,
			uuid,
		};
	} catch {
		throw new Error("upload error");
	}
};

export const getFileURL = async (uuid: string) => {
	try {
		const response = await API.get(`${SERVICES.image_cdn}/${uuid}`, {
			responseType: "blob",
		});
		return response.data;
	} catch {
		throw new Error("get file error");
	}
};
