import CopySvg from "@/_assets/svg/CopySvg";
import { Button } from "@/libs/UI/CustomTags";
import { FC, useCallback, useContext } from "react";
import s from "./HeaderActions.module.scss";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { VariantNewResourceContext } from "../../AddNewResource/ReceivingData/_context/VariantNewResourceContext";
import { toast } from "react-toastify";
type TProps = {
	disabled: boolean;
	handleUpdateStore: () => void;
	handleCopyStore?: (() => string) | null;
};
const HeaderActions: FC<TProps> = ({
	disabled,
	handleUpdateStore,
	handleCopyStore,
}) => {
	const handleCopy = useCallback(async () => {
		if (handleCopyStore) await navigator.clipboard.writeText(handleCopyStore());
	}, [handleCopyStore]);
	const { updateSetting } = useSettingStore();
	const { variantResource } = useContext(VariantNewResourceContext);
	return (
		<div className={s.wrap}>
			{handleCopyStore && (
				<Button
					type="copy"
					onClick={() => {
						handleCopyStore();
						handleCopy();
						toast.success('JSON скопирован')
					}}
					icon={<CopySvg />}
				>
					JSON
				</Button>
			)}

			<Button
				type="primary"
				disabled={disabled}
				onClick={() => {
					handleUpdateStore();
					updateSetting({
						_key: "isCanSent",
						value: true,
						resource: variantResource,
					});
					toast.success('Сохранено')
				}}
			>
				<p>Cохранить</p>
			</Button>
		</div>
	);
};

export default HeaderActions;
