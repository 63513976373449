import ProviderBSvg from "@/_assets/svg/commonData/ProviderBSvg";
import ProviderHeartSvg from "@/_assets/svg/commonData/ProviderHeartSvg";
import RaiffeisenSvg from "@/_assets/svg/commonData/RaiffeisenSvg";
import RenCreditSvg from "@/_assets/svg/commonData/RenCreditSvg";
import SberSvg from "@/_assets/svg/commonData/SberSvg";

export const data = {
	blockedCountriesData: ["Россия", "Германия"],
	languageInterfacesData: [
		"RUS",
		"ENG",
		"UZB",
		"CHN",
		"ESP",
		"FRA",
		"GER",
		"JPN",
		"KOR",
		"ITA",
		"ARA",
		"POR",
		"TUR",
		"HIN",
		"BNG",
		"VIE",
		"THA",
		"POL",
		"NLD",
		"UKR",
		"SWE",
		"NOR",
		"FIN",
		"DAN",
		"GRC",
		"HUN",
		"CZE",
		"SRB",
		"ROU",
		"ISR",
		"SAF",
	],
	walletsData: [
		{
			id: 1,
			icon: SberSvg,
			title: "SberBank",
		},
		{
			id: 2,
			icon: RaiffeisenSvg,
			title: "RaiffeisenBank",
		},
		{
			id: 3,
			icon: RenCreditSvg,
			title: "RenCreditBank",
		},
	],
	providersData: [
		{
			id: 1,

			icon: ProviderHeartSvg,
			title: "firstProvider",
		},
		{
			id: 2,

			icon: ProviderBSvg,
			title: "secondProvider",
		},
	],
};

export const blockedCountriesData = ["Россия", "Германия"];
export const languageInterfacesData = ["RUS", "ENG", "UZB"];
export const walletsData = [
	{
		id: 1,

		icon: SberSvg,
		title: "SberBank",
	},
	{
		id: 2,

		icon: RaiffeisenSvg,
		title: "RaiffeisenBank",
	},
	{
		id: 3,

		icon: RenCreditSvg,
		title: "RenCreditBank",
	},
];
export const providersData = [
	{
		id: 1,
		icon: ProviderHeartSvg,
		title: "firstProvider",
	},
	{
		id: 2,
		icon: ProviderBSvg,
		title: "secondProvider",
	},
];
