import * as React from "react";

const CloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_663_9645)">
      <path
        stroke="#9C9DA9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="m4.667 4.667 6.666 6.667m-6.666 0 6.666-6.667"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_663_9645">
        <path fill="#fff" d="M0 0h16v16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default CloseSvg;
