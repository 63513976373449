import * as React from "react";

const FilterCalendarSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#9C9DA9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.667"
      d="M6.667 1.667V5M13.334 1.667V5M15.833 3.333H4.167C3.247 3.333 2.5 4.079 2.5 5v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V5c0-.92-.746-1.667-1.667-1.667M2.5 8.333h15M6.667 11.667h.008M10 11.667h.008M13.334 11.667h.008M6.667 15h.008M10 15h.008M13.334 15h.008"
    ></path>
  </svg>
);

export default FilterCalendarSvg;
