import { ArticlesSvg, HistorySvg, NewsSvg, RobotsSvg } from "@/_assets/svg/nav";

export const INDEX_PAGE = "/";
const ADMIN_PAGE = "admin";

const CASINO = "casino";
const ADD_CASINO = "add-casino";
const UPDATE_CASINO = "update-casino";
const NEWS = "news";
const ADD_NEWS = "add-news";
const UPDATE_NEWS = "update-news";
const ARTICLE = "article";
const ADD_ARTICLE = "add-article";
const UPDATE_ARTICLE = "update-casino";
const HISTORY = "history";
const ADD_HISTORY = "add-history";
const UPDATE_HISTORY = "update-history";
const ACCOUNT = "account";
export const routes = {
	ADMIN_PAGE,
	CASINO,
	ADD_CASINO,
	UPDATE_CASINO,
	NEWS,
	ADD_NEWS,
	UPDATE_NEWS,
	ARTICLE,
	ADD_ARTICLE,
	UPDATE_ARTICLE,
	HISTORY,
	ADD_HISTORY,
	UPDATE_HISTORY,
	ACCOUNT,
	SETTING_ROBOTS: "setting-robots",
};
export const navigate = [
	{
		name: "Казино",
		link: CASINO,
		icon: ArticlesSvg,
	},
	{
		name: "Новости",
		link: NEWS,
		icon: NewsSvg,
	},
	{
		name: "Статьи",
		link: ARTICLE,
		icon: ArticlesSvg,
	},
	{
		name: "Истории",
		link: HISTORY,
		icon: HistorySvg,
	},
	{
		name: "Robots.txt",
		link: routes.SETTING_ROBOTS,
		icon: RobotsSvg,
	},
];
