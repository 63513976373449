import { create } from "zustand";
import { TSeoState } from "./seo.type";
import { generateLink } from "@/libs/utils/generateLink";
// metaRus metaEng
export const INIT_SEO_DATA = {
	name: "",
	link: "",
	meta_title: "",
	meta_description: "",
	title: "",
	description: "",
	fileURL: "",
};
export const useSeoStore = create<TSeoState>((set) => ({
	seoData: INIT_SEO_DATA,
	setSeoData: ({ field, value }) =>
		set((state) => {
			return {
				seoData: {
					...state.seoData,
					link: field === "title" ? generateLink(value) : state.seoData.link,
					[field]: value,
				},
			};
		}),
}));
