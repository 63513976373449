import React from 'react'

const CheckboxArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 8l2.829 2.828 5.656-5.657"
      ></path>
    </svg>
  )
}

export default CheckboxArrowSvg