import { routes } from "@/constants/routes";
import {
	TArticlesItemRes,
	TUpdateArticle,
} from "@/services/API/articles/articles.type";
import { useUpdateArticleById } from "@/services/API/articles/hook";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { TVariantNewResource } from "@/store/newResourceStore/type";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ItemAddedContent } from "../../_common/_comp";
import s from "./ListAddedArticles.module.scss";

type TProps = {
	variantContent: TVariantNewResource;
	data: TArticlesItemRes[];
};

const ListAddedArticles: FC<TProps> = ({ variantContent, data }) => {
	const navigate = useNavigate();
	const { mutateAsync: updateItem } = useUpdateArticleById();
	const settings = useSettingStore();
	const fetchUpdate = async (params: TUpdateArticle) => {
		await updateItem({
			id: params.id,
			body: {
				article: { ...params, hidden: !params.hidden },
				language: {
					lang: settings.getCurrentLang("articles"),
				},
			},
		});
	};

	return (
		<div className={s.list}>
			{data.map((article) => {
				return (
					<ItemAddedContent
						key={article.id}
						id={article.id}
						isHidden={article.hidden}
						title={article.title}
						variantContent={variantContent}
						callbackVisible={() => {
							fetchUpdate(article);
						}}
						callbackEdit={() => {
							navigate(routes.UPDATE_ARTICLE, {
								state: {
									itemId: article.id,
								},
							});
						}}
					/>
				);
			})}
		</div>
	);
};
export default ListAddedArticles;
