import DeleteSvg from "@/_assets/svg/DeleteSvg";
import LangSelector from "@/components/LangSelector/LangSelector";
import Switch from "@/components/Switch/Switch";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { Button, P } from "@/libs/UI/CustomTags";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import { TVariantRequest } from "@/store/newResourceStore/type";
import { FC, useContext, useMemo } from "react";
import { VariantNewResourceContext } from "../ReceivingData/_context/VariantNewResourceContext";
import s from "./SettingWidget.module.scss";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import PublishCalendar from "../../_comp/PublishCalendar/PublishCalendar";

type TProps = {
	editFor: TVariantRequest;
	handleRemove: () => void;
	handleSent?: () => void;
};
const SettingWidget: FC<TProps> = ({
	handleRemove,
	handleSent = () => {},
	editFor,
}) => {
	const { settingObj, updateSetting } = useSettingStore();

	const { getLocalization } = useLanguage();
	const { variantResource } = useContext(VariantNewResourceContext);
	const removeText = useMemo(() => {
		if (variantResource === "articles") return "статью";
		if (variantResource === "casino") return "казино";
		if (variantResource === "history") return "историю";
		if (variantResource === "news") return "новость";
	}, [variantResource, editFor]);
	return (
		<>
			<div className={s.setting}>
				<div className={s.setting_main}>
					<P size="m" weight={600}>
						{getLocalization("Настройки")}
					</P>
					<div className={s.wrap}>
						<Button
							type="primary_row"
							onClick={handleSent}
							disabled={!settingObj[variantResource].isCanSent}
						>
							Опубликовать на сайте
						</Button>
						<div className={s.body}>
							{editFor === "UPDATE" && (
								<div className={s.row}>
									<P size="s">
										{!settingObj[variantResource].isHidden
											? getLocalization("Показать на сайте")
											: getLocalization("Cкрыть на сайте")}
									</P>
									<Switch
										isActive={!settingObj[variantResource].isHidden}
										toggle={() => {
											updateSetting({
												_key: "isHidden",
												value: !settingObj[variantResource].isHidden,
												resource: variantResource,
											});
										}}
									/>
								</div>
							)}

							<div className={s.row}>
								<P size="s">{getLocalization("Язык страницы")}</P>
								<div className={s.langSelector}>
									<LangSelector
										activeLang={settingObj[variantResource].currentLang}
										callback={(e) => {
											updateSetting({
												_key: "currentLang",
												resource: variantResource,
												value: e,
											});
										}}
										isActiveSelector={editFor === "ADD"}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={s.setting_footer} onClick={handleRemove}>
					<DeleteSvg />
					<P size="s" color="red">
						{getLocalization("Удалить")} {removeText}
					</P>
				</div>
			</div>
			{variantResource !== "casino" && (
				<div className={s.setting}>
					<P size="m" weight={600}>
						{getLocalization("Дата и время публикации")}
					</P>
					<PublishCalendar
						value={settingObj[variantResource].publish_at}
						onChange={(e) =>
							updateSetting({
								_key: "publish_at",
								value: e,
								resource: variantResource,
							})
						}
					/>
				</div>
			)}
		</>
	);
};
export default SettingWidget;
