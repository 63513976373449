import UploadImage from "@/features/Constructor/_common/_comp/UploadImage/UploadImage";
import { Input } from "@/libs/UI";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { useSeoStore } from "@/store/newResourceStore/casino";
import s from "./SeoResource.module.scss";

const SeoResource = () => {
	const { seoData, setSeoData } = useSeoStore();
	const settings = useSettingStore();
	return (
		<div className={s.seo}>
			<div className={s.inputFile_block}>
				<p>Логотип</p>
				<div className={s.inputFile}>
					<UploadImage
						fileURL={seoData.fileURL}
						onChange={(e) => setSeoData({ field: "fileURL", value: e })}
						idInput={"seo"}
					/>
				</div>
			</div>
			<Input
				placeholder="Название казино"
				value={seoData.name}
				onChange={(e) => setSeoData({ field: "name", value: e.target.value })}
			/>

			<div className={s.inputs_block}>
				<p>Meta {settings.getCurrentLang("casino").toUpperCase()}</p>
				<div className={s.inputs}>
					<Input
						placeholder="Ссылка"
						value={seoData.link}
						onChange={(e) => {
							setSeoData({
								field: "link",
								value: e.target.value,
							});
						}}
					/>

					<Input
						placeholder="Meta Title"
						value={seoData.meta_title}
						onChange={(e) => {
							setSeoData({
								field: "meta_title",
								value: e.target.value,
							});
						}}
					/>
					<Input
						placeholder="Meta Description"
						value={seoData.meta_description}
						onChange={(e) =>
							setSeoData({
								field: "meta_description",
								value: e.target.value,
							})
						}
					/>
					<Input
						placeholder="Title"
						value={seoData.title}
						onChange={(e) =>
							setSeoData({
								field: "title",
								value: e.target.value,
							})
						}
					/>
					<Input
						placeholder="Description"
						value={seoData.description}
						onChange={(e) =>
							setSeoData({
								field: "description",
								value: e.target.value,
							})
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default SeoResource;
