import ArrowSelectorSvg from "@/_assets/svg/ArrowSelectorSvg";
import { useFilter } from "@/libs/context/FilterContext/FilterContext";
import { P } from "@/libs/UI/CustomTags";
import cn from "classnames";
import { FC, useEffect } from "react";
import { TPublicatedSelector, TPublicatedSelectorKey } from "../../type";
import s from "./ItemStatus.module.scss";

type TProps = {
	dataSelector: TPublicatedSelector[];
	updateDataSelector: (key: TPublicatedSelectorKey) => void;
};
const ItemStatus: FC<TProps> = ({ dataSelector, updateDataSelector }) => {
	const { filterSelectors } = useFilter()
	const { onChangeFilter, filters } = useFilter()
	const activeItem = dataSelector.find((e) => e.active);

	useEffect(()=>{
		if(activeItem && (!filters.date_from || !filters.date_to)){
			filterSelectors({keyType: "publicated", keyValue: activeItem.key})
		}
	}, [activeItem])

	useEffect(() => {
		if(filters.date_from && filters.date_to) {
			if(new Date(filters.date_from) < new Date() && new Date(filters.date_to) > new Date()) {
				updateDataSelector('all-publicated')
			}
			else if(new Date(filters.date_from) > new Date()){
				updateDataSelector("not-publicated")
			}
			else if(new Date(filters.date_to) < new Date()){
				updateDataSelector('publicated')
			}
		}
	}, [filters.date_from, filters.date_to])

	return (
		<>
			<div className={cn(
				s.item, s.itemChosen, 
				(!activeItem 
				|| (filters.date_from && filters.date_to)) && s.placeholder, 
			)}>
				<div className={s.box}>
					<P size="s">{activeItem && (filters.date_from || filters.date_to) ? activeItem.label : 'Публикации'}</P>
				</div>
				{(!filters.date_from || !filters.date_to) && <ArrowSelectorSvg />}
			</div>

			{dataSelector.map((item) => {
				return (
					<div
						key={item.key}
						className={cn(s.item, s[item.key], { [s.isActive]: item.active })}
						onClick={() => updateDataSelector(item.key)}
					>
						<div className={s.box}>
							<P size="s">{item.label}</P>
						</div>
					</div>
				);
			})}
		</>
	);
};
export default ItemStatus;
