import cn from "classnames";

import { FC } from "react";
import s from "./P.module.scss";
import { IChildren } from "@/types/IChildren";
import { IP } from "./type";
const P: FC<IChildren & IP> = ({
	children,
	size = "s",
	color = "default",
	weight,
}) => {
	return (
		<p className={cn(s.p, s[size], s[color])} style={{ fontWeight: weight }}>
			{children}
		</p>
	);
};
export default P;
