import React from 'react'

const SelectDelBtnSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#9C9DA9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M7.222 7.222l5.556 5.556m-5.556 0l5.556-5.556"
      ></path>
    </svg>
  )
}

export default SelectDelBtnSvg