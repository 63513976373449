import WrapperAddNewNews from "@/features/Constructor/News/WrapperAddNewNews";
import { useLocation } from "react-router-dom";
import { TLocationState } from "./type";

export const UpdateNews = () => {
	const { state }: TLocationState = useLocation();
	return (
		<WrapperAddNewNews
			editFor="UPDATE"
			labelPage="Обновление новости"
			id={state.itemId}
		/>
	);
};

export default UpdateNews;
