import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
	INIT_CONSTRUCTOR_DATA,
	useConstructorStore,
} from "../_common/constructor/constructorStore";
import { useBonusStore } from "./bonuses/bonuses";
import { INIT_COMMON_DATA, useCommonStore } from "./common/common";
import { INIT_SEO_DATA, useSeoStore } from "./seo/seo";
import {
	TCasinoDataStore,
	TCasinoStore,
	TCasinoStorePersist,
} from "./casinoStore.type";
import {
	INIT_SETTING_DATA,
	useSettingStore,
} from "../_common/setting/settingStore";
import {
	TCasinoCreateReq,
	TCasinoUpdateReq,
} from "@/services/API/casino/casino.type";
import { generateLink } from "@/libs/utils/generateLink";

const INIT_CASINO_OBJECT = {
	id: -1,
	commonStore: INIT_COMMON_DATA,
	bonusStore: "",
	reviewStore: INIT_CONSTRUCTOR_DATA.casino,
	seoStore: INIT_SEO_DATA,
};
export const useCasinoStore = create<
	TCasinoStore,
	[["zustand/persist", TCasinoStorePersist]]
>(
	persist(
		(set, get) => {
			return {
				casinoObj: {
					id: -1,
					commonStore: useCommonStore.getState().commonData,
					bonusStore: "",
					reviewStore: useConstructorStore.getState().bindStore.casino,
					seoStore: useSeoStore.getState().seoData,
				},
				setting: useSettingStore.getState().settingObj.casino,

				bindActionData: {
					updateCasinoData: () => {
						set((state) => ({
							...state,
							casinoObj: {
								...state.casinoObj,
								commonStore: useCommonStore.getState().commonData,
								bonusStore: useBonusStore.getState(),
								reviewStore: useConstructorStore.getState().bindStore.casino,
								seoStore: useSeoStore.getState().seoData,
							},
							setting: useSettingStore.getState().settingObj.casino,
						}));
					},
					loadCasinoData: (data) => {
						const storedData = {...get(), ...data};

						if (storedData?.casinoObj && storedData?.setting) {
							useConstructorStore.setState((state) => ({
								...state,
								bindStore: {
									...INIT_CONSTRUCTOR_DATA,
									casino: storedData.casinoObj.reviewStore,
								},
							}));
							useSeoStore.setState((state) => ({
								...state,
								seoData: {
									...INIT_CASINO_OBJECT.seoStore,
									...storedData.casinoObj.seoStore,
								},
							}));
							useCommonStore.setState((state) => ({
								...state,
								commonData: {
									...INIT_CASINO_OBJECT.commonStore,
									...storedData.casinoObj.commonStore,
								},
							}));
							useCommonStore.setState((state) => ({
								...state,
								commonData: {
									...INIT_CASINO_OBJECT.commonStore,
									...storedData.casinoObj.commonStore,
								},
							}));
							useSettingStore.setState((state) => ({
								settingObj: {
									...state.settingObj,
									casino: {
										...INIT_SETTING_DATA,
										...storedData.setting,
										currentLang: state.settingObj.casino.currentLang,
									},
								},
							}));
							set((state) => ({
								...state,
								setting: storedData.setting,
								casinoObj: {
									...state.casinoObj,
									id: get().casinoObj?.id || -1
								}
							}));
						}
					},
					removeCasinoData: () => {
						set({
							casinoObj: INIT_CASINO_OBJECT,
							setting: INIT_SETTING_DATA,
						});

						localStorage.removeItem("casino-storage");
					},
				},

				///
				bindTransformData: {
					getDataForRequest: ({ lang, variantRequest = "ADD" }) => {
						const storedData = get().casinoObj;
						let sent_object: TCasinoCreateReq;
						let update_object: TCasinoUpdateReq;
						sent_object = {
							casino: {
								link: generateLink(storedData.seoStore.link),
								logo: storedData.seoStore.fileURL,
								name: storedData.seoStore.name,
								meta_title: storedData.seoStore.meta_title,
								meta_description: storedData.seoStore.meta_description,
								bonus: {
									text: storedData.commonStore.promos.promoDescription,
									promocode: storedData.commonStore.promos.promoTitle,
								},
								languages: storedData.commonStore.selects.languageInterfaces,
								payments: storedData.commonStore.wallets,
								grade: [
									{
										label: "Надежность",
										count: Number(storedData.commonStore.ratings.reliability),
									},
									{
										label: "Оценка игроков",
										count: Number(storedData.commonStore.ratings.playerRating),
									},
									{
										label: "Вывод средств",
										count: Number(storedData.commonStore.ratings.withdrawal),
									},
									{
										label: "Скорость",
										count: Number(storedData.commonStore.ratings.speed),
									},
								],
								blocked: storedData.commonStore.selects.blockedCountries,
								rating: {
									survey: {
										title: storedData.seoStore.title,
										description: storedData.seoStore.description,
										rating_content: storedData.reviewStore,
									},
									bonus: "",
									banned_countries: [""],
									news: "",
								},
							},
							language: {
								lang: lang,
							},
						};
						if (variantRequest === "UPDATE") {
							update_object = {
								...sent_object,
								casino: {
									...sent_object.casino,
									id: get().casinoObj.id || -1,
									hidden: get().setting.isHidden,
								},
							};
							return update_object;
						}
						return sent_object;
					},
					setDataFromRequest: ({ dataRes }) => {
						let storedData = get().casinoObj;
						const current_lang =
							useSettingStore.getState().settingObj.casino.currentLang;
						storedData = {
							seoStore: {
								fileURL: dataRes?.data.logo || "",
								name: dataRes?.data.name || "",
								link: dataRes?.data.link || "",
								meta_title: dataRes?.data.meta_title || "",
								meta_description: dataRes?.data.meta_description || "",
								title: dataRes?.data.rating.survey.title || "",
								description: dataRes?.data.rating.survey.description || "",
							},
							commonStore: {
								promos: {
									promoDescription: dataRes?.data.bonus.text || "",
									promoTitle: dataRes?.data.bonus.promocode || "",
								},
								selects: {
									languageInterfaces:
										dataRes?.data.languages ||
										INIT_CASINO_OBJECT.commonStore.selects.languageInterfaces,
									blockedCountries:
										dataRes?.data.blocked ||
										INIT_CASINO_OBJECT.commonStore.selects.blockedCountries,
								},
								wallets:
									dataRes?.data.payments ||
									INIT_CASINO_OBJECT.commonStore.wallets,
								ratings: {
									reliability:
										String(
											dataRes?.data.grade.find(
												(grade: any) => grade.label === "Надежность"
											)?.count
										) || "0",
									playerRating:
										String(
											dataRes?.data.grade.find(
												(grade: any) => grade.label === "Оценка игроков"
											)?.count
										) || "0",
									withdrawal:
										String(
											dataRes?.data.grade.find(
												(grade: any) => grade.label === "Вывод средств"
											)?.count
										) || "0",
									speed:
										String(
											dataRes?.data.grade.find(
												(grade: any) => grade.label === "Скорость"
											)?.count
										) || "0",
								},
							},
							reviewStore:
								dataRes?.data.rating.survey.rating_content ||
								INIT_CONSTRUCTOR_DATA.casino,

							bonusStore: useBonusStore.getState(),
						} as TCasinoDataStore;

						set((state) => ({
							...state,
							casinoObj: {
								...storedData,

								id: dataRes?.data.id || -1,
							},
							setting: {
								...get().setting,
								currentLang: dataRes?.lang || current_lang,
								isHidden:
									dataRes?.data.hidden !== undefined
										? dataRes.data.hidden
										: true,
							},
						}));
						get().bindActionData.loadCasinoData();
						return storedData;
					},
				},
			};
		},
		{
			name: "casino-storage",
			storage: createJSONStorage(() => localStorage),
			partialize: (state) => {
				return { casinoObj: state.casinoObj, setting: state.setting };
			},
		}
	)
);
