import { toastGetItemLang } from "@/libs/utils/toastGetAllLang";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
	createCasinoById,
	deleteCasinoById,
	getCasinoById,
	getTopCasino,
	updateCasinoById,
} from "./casino";
import {
	TCasinoCreateReq,
	TCasinoDeleteRes,
	TParamsCasinoListReq,
	TParamsCasinoUpdateReq,
	TParamsSingleCasinoReq,
} from "./casino.type";

export const useGetTopCasino = (params: TParamsCasinoListReq) => {
	return useQuery(["topCasino", params.lang], () => getTopCasino(params), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log("get all casino", error);
		},
	});
};

export const useGetCasinoById = () => {
	return useMutation(
		async ({ lang, id }: TParamsSingleCasinoReq) => {
			const [data] = await Promise.allSettled([getCasinoById({ lang, id })]);
			toastGetItemLang({
				lang,
				status: data.status,
			});

			return {
				dataRes: data.status === "fulfilled" ? data.value : null,
				error: data.status === "rejected" ? data.reason : null,
			};
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log("get all langs news", error);
			},
		}
	);
};

export const useCreateCasino = () => {
	return useMutation(
		async ({ casino, language }: TCasinoCreateReq) => {
			return await createCasinoById({ casino, language });
		},
		{
			onSuccess: (data) => {
				toast.success("Казино добавлено");
			},
			onError: (error) => {
				toast.error("Ошибка добавления");
				console.log("create casino", error);
			},
		}
	);
};

export const useUpdateCasinoById = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ id, body }: TParamsCasinoUpdateReq) => {
			return await updateCasinoById({ id, body });
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("topCasino");
				toast.success("Казино обновлено");
			},
			onError: (error) => {
				toast.error("Ошибка обновления");
				console.log("update casino", error);
			},
		}
	);
};

export const useDeleteCasinoById = () => {
	return useMutation(
		async ({ lang, id }: TCasinoDeleteRes) => {
			return await deleteCasinoById({ lang, id });
		},
		{
			onSuccess: (data) => {
				toast.success("Казино удалено");
			},
			onError: (error) => {
				toast.error("Ошибка удаления");
				console.log("delete casino", error);
			},
		}
	);
};
