import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { TFilterContextProps, TFilterProps, TFilterSelectorKey, TFilterState } from "./type";
import { TPublicatedSelectorKey, TStatusSelectorKey } from "@/features/Constructor/_common/PanelLightSetting/type";
  
const INIT_FILTER: TFilterState = {
	order: 'desc',
}
export type TFilterContext = TFilterContextProps;
export const FilterContext = createContext<TFilterContext>({} as TFilterContext);
export const useFilter = () => {
  const context = useContext<TFilterContext>(FilterContext);
  return context;
};

export const FilterProvider: FC<PropsWithChildren> = ({ children }) => {
  const [filters, setFilters] = useState<TFilterState>(INIT_FILTER);
  const [isThrow, setIsThrow] = useState<boolean>(false)

  const onChangeFilter = React.useCallback(({ value, key }: TFilterProps) => {
    setFilters(prevFilters => {
      if(value === null || value === ''){
        const updatedFilters = Object.fromEntries(
          Object.entries(prevFilters).filter(([k]) => k !== key)
        ) as TFilterState;
  
        return updatedFilters;
      }
      return { ...prevFilters, [key]: value } 
    });
  }, []);

	const reset = React.useCallback(() => {
    filters.title
		? setFilters({...INIT_FILTER, title: filters.title})
		: setFilters({...INIT_FILTER})
	}, [filters.title]);

  useEffect(() => {
    ((Object.keys(filters).length > 1 && !Object.keys(filters).includes('title')) 
    || Object.keys(filters).length > 2) ? setIsThrow(true) : setIsThrow(false)
  }, [filters])

  const filterSelectors = (
    {
      keyValue, 
      keyType
    }:{
      keyValue: TStatusSelectorKey | TPublicatedSelectorKey | Date[] | string;
      keyType: TFilterSelectorKey;
     }) => {
    switch(keyType){
      case 'status': 
        switch(keyValue) {
          case 'active':
            onChangeFilter({key: 'activity', value: true}) 
            break;
          case 'inactive': 
            onChangeFilter({key: 'activity', value: false}) 
            break;
          case 'all':
            onChangeFilter({key: 'activity', value: null}) 
            break;
        }
        break;
      case 'publicated':
        switch(keyValue) {
          case 'publicated':
					  onChangeFilter({key: 'date_to', value: new Date().toISOString()}) 
					  onChangeFilter({key: 'date_from', value: null}) 
					  break;
          case 'not-publicated': 
            onChangeFilter({key: 'date_to', value: null}) 
            onChangeFilter({key: 'date_from', value: new Date().toISOString()}) 
            break;
          case 'all-publicated':
            onChangeFilter({key: 'date_to', value: null}) 
            onChangeFilter({key: 'date_from', value: null}) 
            break;
        }
        break;
      case 'calendar': 
        if (keyValue.length > 1) {
          const selected = keyValue as Date[]
          onChangeFilter({ key: "date_from", value: selected[0].toISOString() });
          onChangeFilter({ key: "date_to", value: selected[1].toISOString() });
        } else {
          onChangeFilter({ key: "date_from", value: null });
          onChangeFilter({ key: "date_to", value: null });
        }
        break;
    }
  }

  const values={
    filters,
    isThrow,
    reset,
    onChangeFilter,
    filterSelectors
  }

  return (
    <FilterContext.Provider value={values}>{children}</FilterContext.Provider>
  );
};
  