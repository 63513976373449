import { TContentTypeKey } from '@/store/newResourceStore/_common/constructor/types/IConstructorContent'
import { FC } from 'react'
const EmbeddedVariantSvg: FC<{ _key: TContentTypeKey }> = ({ _key }) => {
	if (_key === 'defaultText') return <DefaultTextSvg />
	if (_key === 'bigImage') return <BigImageSvg />
	if (_key === 'rowImages') return <RowImagesSvg />
	if (_key === 'prosAndCons') return <ProsAndConsSvg />
	if (_key === 'list' || _key === 'listWithDot' || _key === 'listWithNumber')
		return <ListSvg />
	if (_key === 'quote') return <QuoteSvg />
	if (_key === 'table') return <TableSvg />
	if (_key === 'tableMultiple') return <TableSvg />
	if (_key === 'rowImageText') return <div>new variant</div>
	return null
}

const DefaultTextSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="#fff"
				d="M9.952 6.25c-.43 0-.832 0-1.16.049-.371.055-.752.186-1.057.525-.294.327-.398.717-.443 1.089-.042.348-.042.78-.042 1.267v.57a.75.75 0 001.5 0v-.528c0-.543.001-.882.031-1.129.014-.116.032-.183.046-.22.012-.033.02-.043.023-.046h.001c.001-.002.002-.003.011-.008a.593.593 0 01.152-.037c.204-.03.491-.032.986-.032h1.25v8.5H9.5a.75.75 0 000 1.5H15a.75.75 0 000-1.5h-2.25v-8.5H14c.495 0 .782.002.986.032.092.014.135.03.152.037l.011.007a.133.133 0 01.024.046c.014.038.032.105.046.221.03.247.031.586.031 1.13v.527a.75.75 0 001.5 0v-.57c0-.488 0-.919-.042-1.267-.045-.372-.149-.762-.443-1.09-.305-.338-.686-.469-1.057-.524-.328-.05-.73-.05-1.16-.049H9.952z"
			></path>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M11.943 1.25c-2.309 0-4.118 0-5.53.19-1.444.194-2.584.6-3.479 1.494-.895.895-1.3 2.035-1.494 3.48-.19 1.411-.19 3.22-.19 5.529v.114c0 2.309 0 4.118.19 5.53.194 1.444.6 2.584 1.494 3.479.895.895 2.035 1.3 3.48 1.494 1.411.19 3.22.19 5.529.19h.114c2.309 0 4.118 0 5.53-.19 1.444-.194 2.584-.6 3.479-1.494.895-.895 1.3-2.035 1.494-3.48.19-1.411.19-3.22.19-5.529v-.114c0-2.309 0-4.118-.19-5.53-.194-1.444-.6-2.584-1.494-3.479-.895-.895-2.035-1.3-3.48-1.494-1.411-.19-3.22-.19-5.529-.19h-.114zM3.995 3.995c.57-.57 1.34-.897 2.619-1.069 1.3-.174 3.008-.176 5.386-.176s4.086.002 5.386.176c1.279.172 2.05.5 2.62 1.069.569.57.896 1.34 1.068 2.619.174 1.3.176 3.008.176 5.386s-.002 4.086-.176 5.386c-.172 1.279-.5 2.05-1.069 2.62-.57.569-1.34.896-2.619 1.068-1.3.174-3.008.176-5.386.176s-4.086-.002-5.386-.176c-1.279-.172-2.05-.5-2.62-1.069-.569-.57-.896-1.34-1.068-2.619-.174-1.3-.176-3.008-.176-5.386s.002-4.086.176-5.386c.172-1.279.5-2.05 1.069-2.62z"
				clipRule="evenodd"
			></path>
		</svg>
	)
}
const BigImageSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M11.943 1.25c-2.309 0-4.118 0-5.53.19-1.444.194-2.584.6-3.479 1.494-.895.895-1.3 2.035-1.494 3.48-.19 1.411-.19 3.22-.19 5.529v.114c0 2.309 0 4.118.19 5.53.194 1.444.6 2.584 1.494 3.479.895.895 2.035 1.3 3.48 1.494 1.411.19 3.22.19 5.529.19h.114c2.309 0 4.118 0 5.53-.19 1.444-.194 2.584-.6 3.479-1.494.895-.895 1.3-2.035 1.494-3.48.19-1.411.19-3.22.19-5.529v-.114c0-2.309 0-4.118-.19-5.53-.194-1.444-.6-2.584-1.494-3.479-.895-.895-2.035-1.3-3.48-1.494-1.411-.19-3.22-.19-5.529-.19h-.114zM3.995 3.995c.57-.57 1.34-.897 2.619-1.069 1.3-.174 3.008-.176 5.386-.176s4.086.002 5.386.176c1.279.172 2.05.5 2.62 1.069.569.57.896 1.34 1.068 2.619.174 1.3.176 3.008.176 5.386s-.002 4.086-.176 5.386c-.172 1.279-.5 2.05-1.069 2.62-.57.569-1.34.896-2.619 1.068-1.3.174-3.008.176-5.386.176s-4.086-.002-5.386-.176c-1.279-.172-2.05-.5-2.62-1.069-.569-.57-.896-1.34-1.068-2.619-.174-1.3-.176-3.008-.176-5.386s.002-4.086.176-5.386c.172-1.279.5-2.05 1.069-2.62z"
				clipRule="evenodd"
			></path>
			<path
				stroke="#fff"
				strokeWidth="1.5"
				d="M16.5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
			></path>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M21 18l-3.27-2.149c-1.055-.692-2.626-.761-3.786-.166l-.303.155c-.807.414-1.904.345-2.601-.164l-4.352-3.177c-.869-.634-2.262-.668-3.187-.078L2 13.381"
			></path>
		</svg>
	)
}
const RowImagesSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M21.194 16.793c-.35 2.48-.525 3.721-1.422 4.464-.897.743-2.22.743-4.867.743h-5.81c-2.646 0-3.97 0-4.867-.743-.897-.743-1.072-1.983-1.422-4.464l-.422-3c-.447-3.164-.67-4.745.278-5.77C3.61 7 5.298 7 8.672 7h6.656c3.374 0 5.062 0 6.01 1.024.748.809.767 1.966.521 3.976"
			></path>
			<path
				stroke="#fff"
				strokeWidth="1.5"
				d="M19.562 7a2.132 2.132 0 00-2.1-2.5H6.538a2.132 2.132 0 00-2.1 2.5M17.5 4.5c.028-.26.043-.389.043-.496a2 2 0 00-1.787-1.993C15.65 2 15.52 2 15.26 2H8.74c-.26 0-.391 0-.497.011a2 2 0 00-1.787 1.993c0 .107.014.237.043.496M16.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
			></path>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M20 20l-2.884-2.149c-.93-.692-2.316-.761-3.34-.166l-.266.155c-.712.414-1.68.345-2.294-.164l-3.839-3.177c-.766-.634-1.995-.668-2.81-.078l-1.324.96"
			></path>
		</svg>
	)
}
const ProsAndConsSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M15 10.5h-3m0 0H9m3 0v-3m0 3v3M15 16.5H9M22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464.974.974 1.3 2.343 1.41 4.536"
			></path>
		</svg>
	)
}
const ListSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke="#fff"
				d="M21.194 16.793c-.35 2.48-.525 3.721-1.422 4.464-.897.743-2.22.743-4.867.743h-5.81c-2.646 0-3.97 0-4.867-.743-.897-.743-1.072-1.983-1.422-4.464l-.422-3c-.447-3.164-.67-4.745.278-5.77C3.61 7 5.298 7 8.672 7h6.656c3.374 0 5.062 0 6.01 1.024.748.809.767 1.966.521 3.976"
			></path>
			<path
				stroke="#fff"
				d="M19.562 7a2.132 2.132 0 00-2.1-2.5H6.538a2.132 2.132 0 00-2.1 2.5M17.5 4.5c.028-.26.043-.389.043-.496a2 2 0 00-1.787-1.993C15.65 2 15.52 2 15.26 2H8.74c-.26 0-.391 0-.497.011a2 2 0 00-1.787 1.993c0 .107.014.237.043.496M16.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM20 20l-2.884-2.149c-.93-.692-2.316-.761-3.34-.166l-.266.155c-.712.414-1.68.345-2.294-.164l-3.839-3.177c-.766-.634-1.995-.668-2.81-.078l-1.324.96"
			></path>
		</svg>
	)
}
const QuoteSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="#fff"
				d="M5.002 19.545c.068 0 6.578-.105 6.578-8.918a5.415 5.415 0 10-7.328 5.048v3.12a.75.75 0 00.75.75zM2.25 10.627a3.915 3.915 0 117.83 0c0 5.626-2.91 6.983-4.328 7.313v-2.813a.75.75 0 00-.562-.75 3.908 3.908 0 01-2.94-3.75z"
			></path>
			<path
				fill="#fff"
				d="M16.665 19.545c.067 0 6.585-.105 6.585-8.918a5.416 5.416 0 10-7.335 5.048v3.12a.75.75 0 00.75.75zm-2.745-8.918a3.915 3.915 0 017.83 0c0 5.626-2.918 6.983-4.335 7.313v-2.813a.75.75 0 00-.563-.75 3.907 3.907 0 01-2.932-3.75z"
			></path>
		</svg>
	)
}
const TableSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M3 9h18M3 15h18M9 9v11m6-11v11m-8.8 0h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C21 18.48 21 17.92 21 16.8V7.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C19.48 4 18.92 4 17.8 4H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C3 5.52 3 6.08 3 7.2v9.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C4.52 20 5.08 20 6.2 20z"
			></path>
		</svg>
	)
}
export default EmbeddedVariantSvg
