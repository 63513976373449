import { create } from "zustand";
import { THistoryData, TSeoState } from "./seo.type";
export const INIT_HISTORY_DATA = {
	promocode: "",
	link: "",
	color: "",
	preview_image: "",
	image: "",
	name: "",
	text: "",
} as THistoryData;

export const useSeoStore = create<TSeoState>((set) => ({
	seoData: INIT_HISTORY_DATA,
	setSeoData: ({ field, value }) =>
		set((state) => {
			return {
				seoData: {
					...state.seoData,
					[field]: value,
				},
			};
		}),
}));
