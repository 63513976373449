import { useLanguage } from "@/libs/context/LanguageProvider";
import { TVariantNewResource } from "@/store/newResourceStore/type";
import { FC, useCallback, useState } from "react";
import { VariantNewResourceContext } from "./_context/VariantNewResourceContext";
import InsertJson from "./InsertJson/InsertJson";
import InsertWithConstructor from "./InsertWithConstructor";
import s from "./ReceivingData.module.scss";
import {
	TItemVariantAddedContent,
	TVariantAddedContent,
} from "./ReceivingData.type";
import TabVariantAdded from "./TabVariantAdded/TabVariantAdded";
const VARIANT_EDITOR: TItemVariantAddedContent[] = [
	{
		_key: "json",
		label: "JSON",
	},
	{
		_key: "constructor",
		label: "Новый обзор",
	},
];

const ReceivingData: FC = () => {
	const [activeEditor, setActiveEditor] =
		useState<TVariantAddedContent>("json");

	const handleChangeVariantEditor = useCallback(
		(_key: TVariantAddedContent) => setActiveEditor(_key),
		[]
	);
	return (
		<div className={s.wrap}>
			<div className={s.editor}>
				<TabVariantAdded
					{...{
						handleChangeVariantEditor,
						variantEditor: VARIANT_EDITOR,
						activeEditor,
					}}
				/>
				{activeEditor === "json" && (
					<InsertJson {...{ handleChangeVariantEditor }} />
				)}
				{activeEditor === "constructor" && <InsertWithConstructor />}
			</div>
		</div>
	);
};
export default ReceivingData;
