import React, { FC, InputHTMLAttributes, useState, forwardRef } from "react";
import s from "./Input.module.scss"; // Adjust the path as necessary
import cn from "classnames";
import ShowPass from "@/_assets/svg/ShowPass"; // Adjust the path as necessary
import HidePass from "@/_assets/svg/HidePass"; // Adjust the path as necessary

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
    icon?: boolean;
    error?: string | null;
    label?: string;
}

const Input = forwardRef<HTMLInputElement, IInput>(({ icon, type, label, error, ...props }, ref) => {
    const [viewPass, setViewPass] = useState(false);
    const isPassword = type === "password";

    return (
        <div className={s.labelWrapper}>
            {label && <label>{label}</label>}
            <div
                className={cn(s.inputWrapper, {
                    [s.errorBorder]: error,
                })}
            >
                <input
                    {...props}
                    ref={ref} // Attach the ref here
                    className={s.input}
                    value={props.value || ""}
                    type={isPassword && viewPass ? "text" : type}
                />
                {icon && isPassword && (
                    <div className={s.icon} onClick={() => setViewPass(!viewPass)}>
                        {!viewPass ? <ShowPass /> : <HidePass />}
                    </div>
                )}
                {error && <p className={s.error}>{error}</p>}
            </div>
        </div>
    );
});

// Set displayName for better debugging
Input.displayName = 'Input';

export default Input;
