import { IChildren } from "@/types/IChildren";
import { FC } from "react";
import { HeaderSetting } from "../../_comp";
import s from "./WrapperAddNewResource.module.scss";
import { THeaderSetting } from "../../_comp/HeaderSetting/type";

const WrapperAddNewResource: FC<IChildren & THeaderSetting> = ({
	children,
	title,
	goBack = false,
	rightComp = null,
}) => {
	return (
		<div className={s.wrapper}>
			<HeaderSetting title={title} rightComp={rightComp} goBack={goBack} />
			{children}
		</div>
	);
};
export default WrapperAddNewResource;
