import { SERVICES } from "@/constants/api";
import { API } from "../../helpers/conf-axios";
import { TSignInRequest } from "./auth.type";

export const loginRequest = async (data: TSignInRequest) => {
	const formData = new FormData();
	formData.append("username", data?.username || "");
	formData.append("password", data?.password || "");
	try {
		const response = await API.post(`${SERVICES.auth}/login`, data, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response;
	} catch {
		throw new Error("auth error");
	}
};

export const getMe = async () => {
	try {
		const response = await API.get(`${SERVICES.auth}/me`);
		return response.data;
	} catch {
		throw new Error("getme error");
	}
};
