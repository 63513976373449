import { FilterProvider } from '@/libs/context/FilterContext/FilterContext';
import { TVariantNewResource } from '@/store/newResourceStore/type';
import WrapperArticle from '../Article';
import WrapperCasino from '../Casino';
import WrapperHistory from '../History';
import WrapperNews from '../News';
type TProps = {
	variantContent: TVariantNewResource;
};
const WrapperResourse = ({variantContent}:TProps) => {
  let Component;

  switch (variantContent){
    case 'news': 
      Component = <WrapperNews />
      break;
    case 'articles':
      Component = <WrapperArticle />
      break;
    case 'history':
      Component = <WrapperHistory />
      break;
    case 'casino':
      Component = <WrapperCasino />
      break;
  }

  return (
    <FilterProvider>{Component}</FilterProvider>
  )
}

export default WrapperResourse