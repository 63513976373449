import { TNewsCreateReq, TNewsUpdateReq } from "@/services/API/news/news.type";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
	INIT_CONSTRUCTOR_DATA,
	useConstructorStore,
} from "../_common/constructor/constructorStore";
import {
	INIT_SETTING_DATA,
	useSettingStore,
} from "../_common/setting/settingStore";
import {
	TNewsDataStore,
	TNewsStore,
	TNewsStorePersist,
} from "./newsStore.type";
import { INIT_SEO_DATA, useSeoStore } from "./seo/seo";
import { generateLink } from "@/libs/utils/generateLink";

const INIT_NEWS_OBJECT = {
	id: -1,
	seoStore: INIT_SEO_DATA,
	contentStore: INIT_CONSTRUCTOR_DATA.news,
};
export const useNewsStore = create<
	TNewsStore,
	[["zustand/persist", TNewsStorePersist]]
>(
	persist(
		(set, get) => {
			return {
				newsObj: {
					id: -1,
					seoStore: useSeoStore.getState().seoData,
					contentStore: useConstructorStore.getState().bindStore.news,
				},
				setting: useSettingStore.getState().settingObj.news,

				bindActionData: {
					updateNewsData: () => {
						set((state) => ({
							...state,
							newsObj: {
								...state.newsObj,
								contentStore: useConstructorStore.getState().bindStore.news,
								seoStore: useSeoStore.getState().seoData,
							},
							setting: useSettingStore.getState().settingObj.news,
						}));
					},
					loadNewsData: (data) => {
						const storedData = {...get(), ...data};

						if (storedData?.newsObj && storedData?.setting) {
							useConstructorStore.setState((state) => ({
								...state,
								bindStore: {
									...INIT_CONSTRUCTOR_DATA,
									news: storedData.newsObj.contentStore,
								},
							}));
							useSeoStore.setState((state) => ({
								...state,
								seoData: {
									...INIT_NEWS_OBJECT.seoStore,
									...storedData.newsObj.seoStore,
								},
							}));

							useSettingStore.setState((state) => ({
								settingObj: {
									...state.settingObj,
									news: {
										...INIT_SETTING_DATA,
										...storedData.setting,
										currentLang: state.settingObj.news.currentLang,
									},
								},
							}));
							set((state) => ({
								...state,
								setting: storedData.setting,
								newsObj: {
									...state.newsObj,
									id: get().newsObj?.id || -1
								}
							}));
						}
					},
					removeNewsData: () => {
						set({
							newsObj: INIT_NEWS_OBJECT,
							setting: INIT_SETTING_DATA,
						});
						localStorage.removeItem("news-storage");
					},
				},
				bindTransformData: {
					getDataForRequest: ({ lang, variantRequest = "ADD" }) => {
						const storedData = get().newsObj;
						let sent_object: TNewsCreateReq;
						let update_object: TNewsUpdateReq;
						sent_object = {
							news: {
								publish_at: get().setting.publish_at,
								link: generateLink(storedData.seoStore.link),
								title: storedData.seoStore.title,
								meta_title: storedData.seoStore.meta_title,
								meta_description: storedData.seoStore.meta_description,
								tags: storedData.seoStore.tags,
								banner: storedData.seoStore.fileURL,
								btn_url: "",
								color: storedData.seoStore.color,
								description: storedData.seoStore.description,
								news_content: storedData.contentStore,
							},
							language: {
								lang: lang,
							},
						};
						if (variantRequest === "UPDATE") {
							update_object = {
								...sent_object,
								news: {
									...sent_object.news,
									id: get().newsObj.id || -1,
									hidden: get().setting.isHidden,
								},
							};

							return update_object;
						}
						return sent_object;
					},
					setDataFromRequest: ({ dataRes }) => {
						let storedData = get().newsObj;
						const current_lang =
							useSettingStore.getState().settingObj.news.currentLang;

						storedData = {
							seoStore: {
								color: dataRes?.data.color || "",
								fileURL: dataRes?.data.banner || "",
								description: dataRes?.data.description || "",
								tags: dataRes?.data.tags || INIT_SEO_DATA.tags,
								title: dataRes?.data.title || "",
								meta_title: dataRes?.data.meta_title || "",
								meta_description: dataRes?.data.meta_description || "",
								link: dataRes?.data.link || "",
							},
							contentStore:
								dataRes?.data.news_content || INIT_CONSTRUCTOR_DATA.news,
						} as TNewsDataStore;

						set((state) => ({
							...state,
							newsObj: {
								...storedData,
								id: dataRes?.data.id || -1,
							},
							setting: {
								...get().setting,
								currentLang: dataRes?.lang || current_lang,
								isHidden:
									dataRes?.data.hidden !== undefined
										? dataRes.data.hidden
										: true,

								publish_at:
									dataRes?.data.publish_at || String(new Date().toISOString()),
							},
						}));
						get().bindActionData.loadNewsData();
						return storedData;
					},
				},
			};
		},
		{
			name: "news-storage",

			storage: createJSONStorage(() => localStorage),

			partialize: (state) => {
				return { newsObj: state.newsObj, setting: state.setting };
			},
		}
	)
);
