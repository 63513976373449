import React from "react";

const SelectPlsBtnSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke="#2A354D"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.667"
				d="M15.952 10h-5.893m0 0H4.167m5.892 0V4.107m0 5.893v5.893"
			></path>
		</svg>
	);
};

export default SelectPlsBtnSvg;
